import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 976.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">



<path d="M4318 7683 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4428 7683 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4648 7683 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4898 7673 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4968 7663 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5037 7647 c-4 -10 2 -13 20 -9 18 3 21 7 11 13 -18 12 -25 11 -31
-4z"/>
<path d="M5133 7633 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5208 7623 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5368 7603 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6433 7108 c-21 -27 -26 -81 -29 -343 -1 -148 -7 -284 -13 -302 -6
-20 -5 -72 1 -148 9 -97 9 -118 -3 -129 -13 -12 -121 -55 -274 -110 -72 -25
-184 -69 -220 -86 -25 -12 -98 -38 -145 -52 -19 -5 -48 -16 -65 -23 -35 -16
-177 -70 -325 -125 -212 -77 -246 -103 -277 -210 l-18 -60 -215 -2 -215 -3 0
-25 c0 -25 1 -25 93 -28 l92 -3 0 -264 0 -265 40 0 40 0 2 263 3 262 54 3 c30
2 65 1 76 -2 l22 -6 -28 -157 c-44 -258 -53 -348 -39 -383 13 -32 43 -39 78
-21 9 6 49 22 88 37 l72 26 56 -21 c31 -11 76 -21 99 -21 86 0 180 68 208 149
8 22 24 35 67 54 31 13 60 23 65 20 4 -2 7 -51 7 -109 l0 -104 35 0 34 0 3
113 3 112 110 5 c137 7 184 29 224 103 18 34 37 53 60 63 18 8 35 14 37 14 2
0 4 -62 4 -137 1 -189 24 -238 132 -278 93 -34 216 2 266 78 27 40 27 42 32
270 4 191 8 231 20 238 8 4 53 22 100 39 47 18 123 47 170 66 56 23 93 32 105
28 53 -20 296 -225 455 -384 87 -87 139 -146 207 -235 21 -27 46 -59 56 -70
10 -11 28 -36 40 -55 11 -19 24 -37 27 -40 21 -16 140 -224 195 -339 93 -193
101 -206 121 -206 33 0 42 79 43 362 1 278 -9 378 -59 573 -90 357 -269 686
-536 986 -72 81 -84 105 -84 177 0 29 -6 72 -14 96 -17 57 -46 69 -126 53 -31
-7 -64 -12 -74 -12 -9 0 -45 27 -78 61 -59 58 -170 149 -183 149 -3 0 -22 15
-41 33 -20 17 -43 36 -52 41 -20 11 -153 97 -167 109 -9 7 -106 71 -153 102
-9 6 -25 18 -36 28 -11 9 -26 17 -33 17 -7 0 -13 4 -13 8 0 27 -109 43 -127
20z m157 -1854 c0 -189 -2 -208 -20 -234 -27 -39 -70 -53 -137 -48 -48 4 -61
10 -85 37 l-28 32 2 161 c2 131 6 162 18 169 24 13 224 87 238 88 9 1 12 -46
12 -205z m-564 -20 c-17 -12 -87 -21 -75 -9 8 8 84 31 89 27 3 -2 -4 -10 -14
-18z m-526 -193 c0 -6 -15 -21 -33 -35 -25 -19 -46 -26 -80 -26 -52 0 -63 14
-19 25 15 3 41 13 57 20 59 26 75 29 75 16z"/>
<path d="M3738 6823 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4698 6773 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4788 6763 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3690 6719 c-212 -9 -505 -42 -701 -79 -175 -33 -430 -110 -569 -171
-124 -55 -260 -128 -307 -164 -28 -22 -75 -58 -103 -80 -50 -38 -122 -109
-190 -185 -44 -49 -104 -152 -139 -237 -45 -110 -64 -220 -59 -341 5 -110 19
-144 68 -169 21 -10 28 -8 59 18 222 185 514 330 841 417 202 53 450 97 640
112 74 6 141 13 149 16 26 9 646 4 756 -5 61 -6 169 -15 240 -20 72 -6 213
-23 315 -38 237 -36 285 -36 332 0 44 34 88 140 128 308 5 24 19 76 30 114 80
279 84 311 43 349 -29 28 -93 44 -293 75 -457 72 -835 96 -1240 80z"/>
<path d="M5305 6270 c-4 -12 -5 -24 -2 -27 3 -2 8 5 12 17 4 12 5 24 2 27 -3
2 -8 -5 -12 -17z"/>
<path d="M1552 5760 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2706 5528 c-22 -6 -55 -28 -77 -50 -36 -38 -39 -44 -39 -99 0 -55 3
-62 39 -98 27 -27 62 -47 125 -69 119 -44 141 -56 156 -91 38 -92 -82 -162
-194 -112 -38 16 -50 28 -63 61 -15 37 -19 40 -55 40 -37 0 -38 -1 -32 -27 12
-51 51 -100 101 -124 69 -34 190 -34 250 0 49 28 73 71 73 133 0 85 -58 139
-190 178 -52 16 -84 32 -107 55 -37 37 -40 53 -18 95 21 40 43 50 114 50 49 0
65 -4 89 -25 17 -14 33 -39 37 -55 6 -26 11 -30 42 -30 35 0 36 0 30 36 -8 47
-47 97 -93 119 -45 21 -136 28 -188 13z"/>
<path d="M2137 5524 c-4 -4 -7 -137 -7 -296 l0 -288 191 0 190 0 -3 28 -3 27
-147 3 -148 3 0 104 0 105 130 0 130 0 0 30 0 29 -127 3 -128 3 0 95 0 95 145
5 145 5 0 25 0 25 -181 3 c-99 1 -183 -1 -187 -4z"/>
<path d="M3057 5524 c-4 -4 -7 -18 -7 -30 0 -22 4 -23 93 -26 l92 -3 3 -263 2
-263 38 3 37 3 5 260 5 260 93 3 93 3 -3 27 -3 27 -221 3 c-121 1 -223 -1
-227 -4z"/>
<path d="M1337 5463 c-24 -86 -57 -323 -64 -450 -14 -269 64 -605 202 -868
100 -191 251 -407 369 -529 34 -35 29 -48 -29 -80 -134 -75 -196 -108 -365
-198 -104 -55 -187 -103 -267 -155 -28 -18 -55 -33 -59 -33 -25 0 -199 -97
-226 -126 -18 -19 -39 -34 -48 -34 -22 -1 -58 -34 -70 -66 -50 -129 -69 -255
-76 -494 -7 -219 31 -417 83 -437 46 -18 172 12 223 53 8 7 60 36 115 64 55
29 105 55 110 60 9 7 64 37 215 118 36 19 83 45 105 57 22 12 119 65 215 117
96 52 193 105 215 118 44 25 175 95 270 144 33 17 62 33 65 36 3 3 23 14 45
25 22 11 103 55 180 99 77 44 178 100 225 124 151 80 223 118 313 168 48 27
107 59 130 72 23 13 94 50 157 83 130 67 185 119 214 198 19 54 35 350 36 677
0 194 -7 236 -47 267 -16 13 -23 29 -23 52 0 30 3 33 45 44 25 6 43 16 40 21
-3 5 -18 5 -38 -1 -47 -13 -47 -13 -47 39 l0 48 61 13 c38 7 58 16 52 22 -5 5
-22 5 -43 -1 -19 -6 -47 -10 -62 -10 l-28 0 0 -118 0 -118 -52 -27 c-29 -14
-109 -57 -178 -95 -69 -38 -165 -90 -215 -117 -49 -27 -121 -66 -158 -87 -38
-21 -70 -38 -72 -38 -2 0 -30 -15 -62 -33 -106 -62 -233 -127 -246 -127 -12 1
-24 11 -182 155 -49 44 -116 104 -149 134 -286 255 -508 533 -646 809 -58 116
-150 346 -150 375 0 7 -7 34 -16 60 -23 68 -47 64 -67 -10z"/>
<path d="M3600 5226 l0 -296 35 0 35 0 0 135 0 135 150 0 150 0 0 -135 0 -135
40 0 40 0 0 295 0 295 -34 0 -34 0 -6 -82 c-4 -46 -7 -103 -9 -128 -2 -36 -6
-45 -19 -44 -10 0 -77 1 -149 2 l-131 2 3 123 4 122 -37 3 -38 3 0 -295z"/>
<path d="M4120 5515 c0 -3 24 -49 54 -102 29 -54 74 -138 100 -186 l48 -89 -3
-101 -3 -102 37 -3 37 -3 0 106 0 106 47 87 c26 48 71 129 100 181 29 51 53
97 53 102 0 5 -18 9 -39 9 l-40 0 -76 -145 c-42 -80 -80 -143 -83 -141 -4 2
-38 68 -77 145 l-69 141 -43 0 c-24 0 -43 -2 -43 -5z"/>
<path d="M6790 5215 c0 -157 0 -288 0 -292 0 -5 15 -9 35 -11 l35 -3 0 115 0
116 80 0 c148 0 225 32 256 107 18 42 18 105 -1 149 -18 45 -34 60 -87 84 -36
16 -66 20 -180 20 l-138 0 0 -285z m280 210 c43 -22 60 -53 60 -108 0 -40 -5
-53 -29 -78 -31 -30 -33 -31 -171 -31 l-65 0 -3 94 c-4 143 -8 138 94 138 56
0 95 -5 114 -15z"/>
<path d="M4295 4682 c-10 -20 -32 -68 -51 -107 -34 -75 -55 -153 -36 -141 6 3
13 20 17 36 8 34 26 47 82 56 39 6 40 6 57 -40 10 -25 22 -46 28 -46 6 0 7 8
3 18 -23 56 -24 75 -7 88 16 12 15 13 -5 14 -23 0 -36 29 -49 113 -9 51 -19
54 -39 9z m33 -73 c7 -29 11 -53 9 -55 -1 -2 -22 -6 -45 -10 l-41 -6 27 61
c15 33 29 61 33 61 3 0 11 -23 17 -51z"/>
<path d="M5180 4585 c0 -83 4 -135 10 -135 6 0 10 52 10 135 0 83 -4 135 -10
135 -6 0 -10 -52 -10 -135z"/>
<path d="M5580 4649 l0 -72 -21 27 c-25 32 -55 33 -86 4 l-23 -21 0 56 c0 31
-4 59 -10 62 -6 4 -10 -40 -10 -124 l0 -131 26 0 c38 0 81 29 101 70 l18 35 3
-52 c2 -29 7 -53 12 -53 6 0 9 59 9 135 0 76 -5 135 -10 135 -5 0 -9 -32 -9
-71z m-42 -51 c18 -18 15 -46 -9 -84 -19 -31 -62 -54 -76 -41 -9 10 17 100 34
120 19 20 34 22 51 5z"/>
<path d="M3232 4698 c-7 -7 -12 -27 -12 -45 0 -26 -4 -33 -20 -33 -11 0 -20
-4 -20 -9 0 -5 9 -11 20 -14 18 -5 20 -14 20 -86 0 -47 4 -81 10 -81 6 0 10
35 10 85 0 78 2 85 20 85 11 0 20 5 20 10 0 6 -9 10 -21 10 -17 0 -20 5 -17
33 3 28 7 32 36 35 18 2 30 8 27 13 -9 13 -59 11 -73 -3z"/>
<path d="M4010 4665 c0 -38 -3 -45 -20 -45 -11 0 -20 -4 -20 -10 0 -5 9 -10
20 -10 18 0 20 -7 20 -79 0 -44 5 -83 10 -86 6 -4 10 25 10 79 0 85 0 86 25
86 14 0 25 5 25 10 0 6 -11 10 -25 10 -23 0 -25 4 -25 45 0 25 -4 45 -10 45
-5 0 -10 -20 -10 -45z"/>
<path d="M4860 4581 c0 -76 4 -132 10 -136 6 -4 10 13 10 44 0 28 3 51 6 51 3
0 31 -22 61 -50 31 -27 60 -47 65 -44 5 4 -16 29 -47 56 l-56 51 29 17 c61 38
80 96 40 124 -13 9 -43 16 -70 16 l-48 0 0 -129z m110 94 c6 -8 9 -23 5 -34
-8 -26 -52 -61 -76 -61 -16 0 -19 8 -19 55 l0 55 39 0 c21 0 44 -6 51 -15z"/>
<path d="M5233 4703 c-27 -5 -29 -16 -7 -34 13 -10 17 -10 25 2 8 12 13 41 7
38 -2 -1 -13 -4 -25 -6z"/>
<path d="M2767 4682 c-10 -10 -17 -32 -17 -49 0 -25 9 -38 43 -65 54 -42 66
-58 67 -84 0 -35 -67 -47 -104 -19 -25 19 -37 19 -30 1 12 -31 95 -47 135 -26
13 7 19 21 19 43 0 38 -9 52 -67 96 -66 50 -56 101 20 101 23 0 36 4 32 10
-11 17 -80 12 -98 -8z"/>
<path d="M3330 4655 c0 -38 -3 -45 -20 -45 -11 0 -20 -4 -20 -10 0 -5 9 -10
20 -10 17 0 20 -7 20 -42 0 -53 11 -118 19 -118 3 0 6 35 6 77 0 73 1 78 23
81 30 5 28 22 -3 22 -23 0 -25 4 -25 45 0 25 -4 45 -10 45 -5 0 -10 -20 -10
-45z"/>
<path d="M4708 4664 c-3 -34 -6 -37 -36 -39 -40 -3 -69 -28 -77 -66 -8 -37
-25 -38 -25 -1 0 35 -26 72 -50 72 -10 0 -30 -14 -43 -31 l-25 -32 -7 32 c-4
17 -10 29 -13 27 -11 -6 -2 -176 9 -176 5 0 9 14 9 30 0 17 9 48 19 69 46 90
83 75 87 -36 3 -82 5 -89 13 -43 l7 35 14 -30 c19 -38 53 -44 91 -16 29 21 29
21 29 1 0 -11 5 -20 10 -20 6 0 10 50 10 130 0 74 -4 130 -9 130 -6 0 -11 -16
-13 -36z m-17 -64 c22 -12 26 -75 6 -101 -13 -18 -50 -39 -67 -39 -37 0 -27
107 13 135 26 18 23 18 48 5z"/>
<path d="M3138 4682 c-22 -4 -23 -16 -3 -32 12 -10 16 -9 21 4 8 22 2 32 -18
28z"/>
<path d="M5051 4614 c-44 -56 -14 -164 45 -164 30 0 64 17 64 31 0 6 -7 6 -19
-1 -42 -22 -91 -1 -91 40 0 16 9 19 53 22 49 3 52 5 49 28 -3 30 -39 70 -63
70 -10 0 -27 -12 -38 -26z m69 -13 c17 -33 11 -41 -30 -41 -44 0 -47 5 -24 38
19 27 40 28 54 3z"/>
<path d="M5322 4633 c-35 -14 -55 -119 -32 -164 15 -27 55 -24 78 6 25 32 32
31 32 -2 0 -14 3 -23 7 -20 12 13 2 141 -13 165 -14 21 -42 27 -72 15z m56
-53 c3 -46 -26 -105 -53 -105 -18 0 -20 7 -20 56 0 63 17 92 49 87 16 -2 22
-11 24 -38z"/>
<path d="M5636 4618 c-43 -67 -11 -168 52 -168 32 0 71 24 59 36 -4 4 -13 2
-19 -4 -19 -19 -66 -14 -78 7 -21 41 -13 51 40 51 46 0 50 2 50 24 0 33 -37
76 -65 76 -14 0 -31 -9 -39 -22z m68 -20 c23 -33 20 -38 -24 -38 -41 0 -47 8
-30 41 14 25 35 24 54 -3z"/>
<path d="M3700 4598 c-22 -36 -27 -107 -10 -139 17 -30 57 -24 79 11 20 33 31
30 31 -8 0 -14 3 -22 7 -18 13 12 2 140 -13 164 -23 35 -69 30 -94 -10z m80
-23 c0 -71 -47 -140 -71 -104 -13 19 -5 104 11 124 7 8 23 15 36 15 21 0 24
-5 24 -35z"/>
<path d="M3862 4618 c-6 -6 -12 -25 -12 -40 0 -21 9 -34 40 -54 24 -16 40 -34
40 -47 0 -17 -5 -19 -45 -15 -25 3 -45 2 -45 -2 0 -23 98 -28 106 -5 11 27 -5
60 -41 84 -25 17 -35 31 -33 45 4 25 42 34 65 15 13 -11 15 -10 12 6 -5 24
-67 33 -87 13z"/>
<path d="M5230 4593 c0 -57 12 -143 19 -139 10 6 0 176 -10 176 -5 0 -9 -17
-9 -37z"/>
<path d="M2890 4612 c0 -5 7 -28 15 -53 7 -24 19 -63 25 -86 6 -24 15 -43 20
-43 5 0 17 25 26 55 10 30 20 55 24 55 3 0 14 -23 24 -50 17 -47 32 -68 41
-58 3 2 11 38 20 79 9 41 18 82 21 92 3 9 1 17 -5 17 -6 0 -15 -19 -21 -42 -6
-24 -14 -56 -18 -73 l-8 -30 -24 53 c-12 29 -28 51 -34 49 -6 -2 -19 -26 -28
-53 -18 -53 -18 -52 -43 39 -9 31 -21 57 -26 57 -5 0 -9 -4 -9 -8z"/>
<path d="M3139 4606 c-8 -8 3 -176 12 -176 11 0 12 150 1 168 -5 7 -10 10 -13
8z"/>
<path d="M7619 4450 c-141 -154 -393 -350 -547 -427 -32 -15 -59 -31 -62 -34
-7 -9 -297 -149 -308 -149 -4 0 -27 -9 -49 -20 -23 -11 -63 -27 -90 -36 -26
-9 -61 -22 -78 -29 -34 -14 -153 -54 -235 -79 -55 -16 -75 -22 -180 -50 -30
-8 -75 -20 -100 -27 -25 -7 -65 -16 -90 -20 -25 -4 -83 -16 -130 -27 -47 -11
-119 -25 -160 -32 -41 -6 -109 -18 -150 -26 -41 -7 -136 -20 -210 -29 -74 -8
-163 -20 -196 -25 -34 -6 -110 -10 -170 -10 -60 0 -163 -7 -229 -15 -83 -10
-229 -14 -470 -15 -197 0 -365 -4 -385 -10 -43 -12 -103 -72 -119 -120 -14
-39 -46 -221 -56 -315 -3 -33 -15 -121 -25 -195 -43 -311 -44 -336 -14 -377
38 -51 88 -59 409 -67 336 -9 753 6 985 34 85 11 193 24 240 30 96 12 255 37
315 50 22 5 83 16 135 26 52 9 129 25 170 36 41 11 95 24 120 29 136 28 590
181 725 244 141 65 272 131 332 167 40 24 97 58 127 76 31 18 56 36 56 40 0 5
12 14 26 21 57 25 214 167 347 312 38 42 166 227 189 274 98 199 137 400 118
606 -11 110 -48 225 -82 251 -48 37 -78 25 -159 -62z"/>
<path d="M7970 3930 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3412 2540 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4803 2213 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M4938 2213 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4640 2179 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5418 1233 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4705 1110 c-4 -6 6 -7 27 -3 44 8 48 13 10 13 -17 0 -34 -4 -37 -10z"/>
<path d="M4328 1063 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3528 1033 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3172 720 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
